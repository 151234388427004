<template>
  <!--
    The view for the Full Login Data List
  -->
  <div class="fullLoginData">
    <FullLoginDataList />
  </div>
</template>

<script>
export default {
  name: "FullLoginDataView",
  components: {
    FullLoginDataList: () => import('@/components/SoftwareComponent/FullLoginDataList.vue')
  }
}
</script>